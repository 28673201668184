import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { Radio, RadioGroup } from '@entur/form';
import { Playground } from '~/components/playground/Playground';
import { ImportStatement } from '~/components/ImportStatement';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import doPic from './RadioDo.png';
import dontPic from './RadioDont.png';
import PageHeader from '~/components/PageHeader';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'() => {\n  const [value, setValue] = React.useState(null)\n  return (\n    <RadioGroup\n      name=\"ticket-type\"\n      label=\"Velg billettype\"\n      onChange={e => setValue(e.target.value)}\n      value={value}\n    >\n      <Radio value=\"single\">Enkelbillett</Radio>\n      <Radio value=\"period\">Periodebillett</Radio>\n    </RadioGroup>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Radio,
      RadioGroup,
      Playground,
      ImportStatement,
      DoDontGroup,
      DoDontCard,
      doPic,
      dontPic,
      PageHeader,
      DefaultLayout,
      _frontmatter
    }} style={{
      margin: '5% 0'
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = React.useState(null);
        return <RadioGroup name="ticket-type" label="Velg billettype" onChange={e => setValue(e.target.value)} value={value} mdxType="RadioGroup">
        <Radio value="single" mdxType="Radio">Enkelbillett</Radio>
        <Radio value="period" mdxType="Radio">Periodebillett</Radio>
      </RadioGroup>;
      }}
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "radio"
    }}>{`Radio`}</h3>
    <ImportStatement imports="Radio" mdxType="ImportStatement" />
    <Props of={Radio} mdxType="Props" />
    <p><inlineCode parentName="p">{`<Radio />`}</inlineCode>{` brukes når man har to eller flere motstridende valg å velge mellom. Må brukes inni en `}<inlineCode parentName="p">{`<RadioGroup />`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "radiogroup"
    }}>{`RadioGroup`}</h3>
    <ImportStatement imports="RadioGroup" mdxType="ImportStatement" />
    <Props of={RadioGroup} mdxType="Props" />
    <p><inlineCode parentName="p">{`<RadioGroup />`}</inlineCode>{` lar deg gruppere flere radio buttons under en overskrift. Du kan spesifisere valgt verdi via `}<inlineCode parentName="p">{`value`}</inlineCode>{`-propen, og lytte på endringer med `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` på toppnivå.`}</p>
    <p><inlineCode parentName="p">{`name`}</inlineCode>{`-propen blir propagert ned til hver av `}<inlineCode parentName="p">{`<Radio />`}</inlineCode>{`-elementene, så du slipper å gjøre dette manuelt.`}</p>
    <h2 {...{
      "id": "retningslinjer"
    }}>{`Retningslinjer`}</h2>
    <p>{`Brukes i tilfeller der brukeren kun kan velge ett alternativ. En radioknapp skal være venstrejustert i skjemaer. Den skal alltid stå foran tilhørende ledetekst, og bør aldri stå alene. Valgene bør stå under hverandre i en liste. Dersom du ønsker å vise valgene ved siden av hverandre på en linje, anbefales det heller å bruke Choice Chips.`}</p>
    <p>{`Bruk Checkboxes hvis brukerne har mulighet til å velge flere alternativer fra listen. Om du har flere enn 5 valg, vurder å bruk en Dropdown med single select funksjon istedet.`}</p>
    <DoDontGroup mdxType="DoDontGroup">
  <DoDontCard variant="success" src={doPic} mdxType="DoDontCard">
    Hvis du har mange radioknapper, bør du liste dem under hverandre i en
    kolonnegruppe slikt at det gjør det enkelt for brukeren å skanne raskt
    gjennom listen.
  </DoDontCard>
  <DoDontCard variant="negative" src={dontPic} mdxType="DoDontCard">
    Du bør unngå å plassere radioknapper ved siden av hverandre og i flere
    kolonner.
  </DoDontCard>
    </DoDontGroup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      